import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "./plugins/data_graph.js";

// graphiques
import * as echarts from 'echarts'
import { BaklavaVuePlugin } from '@baklavajs/plugin-renderer-vue'
import '@baklavajs/plugin-renderer-vue/dist/styles.css'


// permission control
import "@/permission";

// icon
import "@/icons";

// maps
import 'leaflet/dist/leaflet.css';

// intégration de la police Effra
import './assets/sass/style.scss'

// intégration bibliothèque de formatage/gestion des dates issue #520
// doc : https://day.js.org/docs/en/display/format v1.11.7
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale( navigator.language || 'fr' ) // use locale globally

// Fonction utilitaire pour formater une date ou renvoyer "n/c" si la date est invalide et/ou si fallback n'est pas fourni
export function date2dayjs(date, format = 'DD/MM/YYYY', fallback = null) {
    if (dayjs(date).isValid()) {
      return dayjs(date).format(format);
    } else {
      console.log('error in date2dayjs function.')
      console.log(date)
      return fallback ? fallback : 'n/c';
    }
}


import BackButtonComponent from "@/components/BackButton/BackButtonComponent";
import MarginFooterComponent from "@/components/MarginFooter/MarginFooterComponent";
import {formator} from '@/utils/formator.js'
import {checkRoles} from '@/utils/checkRoles.js'
import {checkPerms} from '@/utils/checkPerms.js'

// register globally
Vue.component("back-button", BackButtonComponent);
Vue.component("margin-footer", MarginFooterComponent);
Vue.prototype.$formator   = formator;
Vue.prototype.$checkRoles = checkRoles;
Vue.prototype.$checkPerms = checkPerms;
Vue.prototype.$node_env   = process.env.NODE_ENV;
Vue.prototype.$date       = date2dayjs;
Vue.prototype.$echarts    = echarts
Vue.use(BaklavaVuePlugin)

// global filters
import * as filters from "./filters";
// register global utility filters.
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

Vue.config.productionTip = true;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
